/* App.css */
body {
    margin: 0;
    padding: 0;
    background-color: #252525;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    box-sizing: border-box;
    min-height: 100vh;
}


video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.video-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding: 1rem;
    width: 100%;
}

.video-wrapper {
    border-radius: 10px;
    overflow: hidden;
    height: 15rem;
    position: relative;
    min-width: 20rem;
}

.user-video {
    border: 3px solid #9d2323;
}

.partner-video {
    border: 3px solid #669d23;
}

.user-label {
    position: absolute;
    top: 10px;
    left: 10px;
    margin: 0 auto;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1rem;
}


.dashboard-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 0;
    padding: 0;
    margin: 0;
}

.chat-title {
    background-color: #dddddd;
    width: 100%;
    height: 4rem;
    border-radius: 10px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.title {
    padding: 0;
    margin: 0;
    font-size: 2em;
    font-weight: bold;
    color: #ffffff;
}

.subtitle {
    padding: 0;
    margin: 0;
    font-size: 1em;
    color: #9f9f9f;
}

.incoming-call {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
}

.callpopup-wrapper {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}


.chat-session {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 18rem;
}

.no-session {
    position: absolute; /* Position the message absolutely within the container */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the message */
    background-color: #252525;
    border-radius: 4px;
    color: #fff;
    padding: 10px 20px;
    width: 8.5rem;
    opacity: .6;
    text-align: center;
}

.chat-messages {
    height: 200px;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 10px;
}

.input-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    box-sizing: border-box; /* Add box-sizing property */
    background-color: #e0e0e0;
}

.action-button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box; /* Add box-sizing property */
    cursor: pointer;
}

.action-button:hover {
    background-color: #0056b3;
}

.default-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}


.info {
    background-color: #a14747;
    padding: 1rem;
    border-radius: .5rem;
    text-align: center;
    width: 14.3rem;
    min-height: 10rem; /* Set a max height to allow shrinking */
    max-height: 16rem; /* Set a max height to allow shrinking */
    overflow: auto; /* Enable vertical scroll if content exceeds the height */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    justify-content: space-between;
}


.overflow-breakword {
    overflow: hidden;
    word-break: break-word;
    overflow-wrap: break-word;
}

.call-button {
    width: 100%;
    background-color: #669d23;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: .9rem;
}

.disabled {
    position: relative; /* Add a relative position to the element */
    pointer-events: none;
}

.disabled::before {
    border-radius: .5rem;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5); /* Apply a gray overlay */
    z-index: 1; /* Ensure the overlay is above the content */
}

.call-button:hover {
    background-color: #4b731a;
}

.login-button {
    cursor: pointer;
}

.logout-button {
    cursor: pointer;
}

.authentication-section {
    background-color: #dddddd;
    border-radius: 1rem;
    padding: .5rem 1rem;
    font-size: 0.8rem;
    text-align: center;
    max-width: 10rem;
    overflow: hidden;
    word-break: break-word;
    overflow-wrap: break-word;
}

.bold {
    font-weight: bold;
}


.underlined {
    text-decoration: underline;
}

.white{
    color: #fff;
}